import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

const TermsofService = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h2>End-User License Agreement (&quot;EULA&quot;) and Terms of Service</h2>
                        <p>Last updated: May 28, 2024</p>
                        <p>Please read this End-User License Agreement carefully before downloading or using PostUp.</p>
                        <h3>Interpretation and Definitions</h3>
                        <h4>Interpretation</h4>
                        <p>The words of which the initial letter is capitalized have meanings defined under the
                            following conditions. The following definitions shall have the same meaning regardless of
                            whether they appear in singular or in plural.</p>
                        <h4>Definitions</h4>
                        <p>For the purposes of this End-User License Agreement:</p>
                        <ul>
                            <li>
                                <p><strong>Service</strong> means any related websites or other online properties owned
                                    or controlled by PostUp, in addition to the Application, collectively constitute the
                                    "Service"</p>
                            </li>
                            <li>
                                <p><strong>Agreement</strong> means this End-User License Agreement that forms the
                                    entire agreement between You and the Company regarding the use of the Service.
                                </p>
                            </li>

                            <li>
                                <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these
                                    Terms and Conditions that form the entire agreement between You and the Company
                                    regarding the use of the Service.</p>
                            </li>
                            <li>
                                <p><strong>Company</strong> (referred to as either &quot;the
                                    Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                                    refers to PostUp.</p>
                            </li>

                            <li>
                                <p><strong>You</strong> means the individual accessing or using the Service or the
                                    company, or other legal entity on behalf of which such individual is accessing or
                                    using the Service, as applicable.</p>
                            </li>
                            <li>
                                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is
                                    under common control with a party, where &quot;control&quot; means ownership of 50%
                                    or more of the shares, equity interest or other securities entitled to vote for
                                    election of directors or other managing authority.</p>
                            </li>
                            <li>
                                <p><strong>Country</strong> refers to: India</p>
                            </li>
                            <li>
                                <p><strong>Website</strong> refers to PostUp, accessible from <a
                                    href="https://postup.in" rel="external nofollow noopener noreferrer"
                                    target="_blank">https://postup.in</a></p>
                            </li>
                            <li>
                                <p><strong>Application</strong> means the software program provided by the Company
                                    downloaded by You to a Device, named PostUp</p>
                            </li>
                            <li>
                                <p><strong>Device</strong> means any device that can access the Service such as a
                                    computer, a cellphone or a digital tablet.</p>
                            </li>
                            <li>
                                <p><strong>Third-party Social Media Service</strong> means any services or content
                                    (including data, information, products or services) provided by a third-party that
                                    may be displayed, included or made available by the Service.</p>
                            </li>
                            <li>
                                <p><strong>Content</strong> refers to content such as text, images, or other information
                                    that can be posted, uploaded, linked to or otherwise made available by You,
                                    regardless of the form of that content.</p>
                            </li>
                        </ul>
                        <h3>Acknowledgment</h3>
                        <p>By installing Application or accessing PostUp via Website or using any part of the Service,
                            you indicate that you have read, understood, and agreed to be bound by this EULA.
                            If you do not agree to the terms of this EULA or to the Terms of Service, then you are not
                            permitted to use any part of the Service.</p>
                        <p>This Agreement is a legal document between You and the Company and it governs your use of the
                            Service made available to You by the Company.</p>
                        <p>The Service is licensed, not sold, to You by the Company for use strictly in accordance
                            with the terms of this Agreement.</p>
                        <p>You represent that you are over the age of 18. The Company does not permit those under 18 to
                            use the Service.</p>
                        <p>Your access to and use of the Service is also conditioned on Your acceptance of and
                            compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies
                            and procedures on the collection, use and disclosure of Your personal information when You
                            use the Application or the Website and tells You about Your privacy rights and how the law
                            protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                        <h3>License</h3>
                        <h4>Scope of License</h4>
                        <p>The Company grants You a revocable, non-exclusive, non-transferable, limited license to
                            download, install and use the Service strictly in accordance with the terms of this
                            Agreement.</p>
                        <p>The license that is granted to You by the Company is solely for your personal, non-commercial
                            purposes strictly in accordance with the terms of this Agreement.</p>
                        <h3>Third-Party Services</h3>
                        <p>The Application and the Website may display, include or make available third-party content
                            (including data, information, applications and other products services) or provide links to
                            third-party websites or services.</p>
                        <p>You acknowledge and agree that the Company shall not be responsible for any Third-party
                            Services, including their accuracy, completeness, timeliness, validity, copyright
                            compliance, legality, decency, quality or any other aspect thereof. The Company does not
                            assume and shall not have any liability or responsibility to You or any other person or
                            entity for any Third-party Services.</p>
                        <p>You must comply with applicable Third parties' Terms of service when using the Service.
                            Third-party Services and links thereto are provided solely as a convenience to You and You
                            access and use them entirely at your own risk and subject to such third parties' Terms of
                            service.</p>
                        <ul>
                            <li>
                                <a href="https://policies.google.com/terms?hl=en-US"
                                   target="_blank" rel="noreferrer">Google - Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/static?template=terms"
                                   target="_blank" rel="noreferrer">YouTube - Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://m.facebook.com/legal/terms/"
                                   target="_blank" rel="noreferrer">Facebook - Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://help.instagram.com/581066165581870"
                                   target="_blank" rel="noreferrer">Instagram - Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://x.com/en/tos/previous/version_9"
                                   target="_blank" rel="noreferrer">X - Terms of Service</a>
                            </li>
                        </ul>
                        <h3>Account</h3>
                        <p>When creating an Account or Signing Up, you agree to provide true, accurate, current, and
                            complete information as requested by PostUp. You must promptly update this information to
                            ensure its accuracy. PostUp reserves the right to disallow, cancel, remove, or reassign
                            certain display names and permalinks as deemed appropriate by PostUp in its sole discretion.
                            Additionally, PostUp may suspend or terminate your Account without liability if activities
                            occur on your Account that violate this EULA, damage the Service, infringe on
                            third-party rights, harm the reputation of PostUp, or violate any laws or regulations.
                            If emails sent to the provided address are undeliverable, PostUp may terminate your Account
                            without notice or liability to you or any third party.</p>
                        <h3>General Use of Service</h3>
                        <p>PostUp exclusively owns all design rights, databases, compilation, and other intellectual
                            property rights related to the Service, whether registered or unregistered, along with any
                            associated goodwill.</p>
                        <p>Some features and functionalities may be exclusive to either the Website or the App, not
                            both. PostUp, at its sole discretion, may offer functionalities across both platforms and
                            may deactivate functionalities, either temporarily or permanently, at any time, without
                            incurring any liability to you.</p>
                        <h3>Content and License</h3>
                        <ul>
                            <li>
                                <p>This document covers the content that users upload, post, or transmit through the
                                    service, known as "Content". This includes various types of content such as
                                    photographs, text, audio recordings, and any other protected works under laws like
                                    copyright laws. However, it excludes any content belonging to PostUp.</p>
                            </li>
                            <li>
                                <p>You maintain ownership of the rights to your Content, except for any preexisting
                                    content that belongs to third parties. Posting your Content on the platform does not
                                    transfer ownership of your rights to PostUp or any third party.</p>
                            </li>
                            <li>
                                <p>By posting your Content, you grant PostUp an extensive license to use it. This
                                    license allows PostUp to reproduce, distribute, publicly display, perform, create
                                    derivative works, and otherwise utilize your content for various purposes, including
                                    advertising, promoting PostUp and its services, displaying and sharing your content
                                    with other users, and reproducing your content in merchandise. Additionally, PostUp
                                    may sublicense your content to third parties for their use. It's important to note
                                    that you won't receive any payments from PostUp or third-party sublicenses for the
                                    use of your content under this agreement. Even after termination of the license,
                                    PostUp may retain archival copies of your content.</p>
                            </li>
                            <li>
                                <p>The licenses granted to PostUp are perpetual and irrevocable for any of your content
                                    that has already been sublicensed to third parties before the termination date. This
                                    means that once you post content on the platform, it may remain accessible on the
                                    internet indefinitely. Therefore, it's advised to carefully consider before posting
                                    any Content on the platform.</p>
                            </li>
                            <li>
                                <p>While PostUp doesn't screen Content beforehand, it reserves the right to remove,
                                    disallow, block, or delete any content at its discretion. This includes content that
                                    violates the terms of the agreement, applicable laws, or is deemed objectionable by
                                    PostUp. Additionally, PostUp may take action based on complaints from other users,
                                    licensors, copyright owners, or their representatives, with or without prior notice
                                    and without liability to you. Moreover, PostUp may also take remedial action
                                    regarding objectionable content posted on the platform. It's recommended that users
                                    keep copies of their posted content on personal devices or other storage mediums for
                                    permanent access.</p>
                            </li>
                            <li>
                                <p>You should only post user content if you own it or have full authorization to grant
                                    rights for all its elements. If you own rights to a sound recording but not to the
                                    underlying musical works, refrain from posting any audiovisual works containing such
                                    recordings unless you have all necessary permissions regarding the embedded musical
                                    works to grant licenses to PostUp under this EULA. You confirm and assure that: (i)
                                    you own the user content you post or have all necessary rights to grant the license
                                    outlined in this EULA; (ii) posting and using your user content doesn't violate the
                                    rights of any party, including privacy, publicity, copyrights, trademarks, patents,
                                    trade secrets, contract rights, or any other rights, including those of individuals
                                    depicted in your content; (iii) posting your content won't necessitate further
                                    licenses, royalties, fees, compensation, or attribution to third parties; and (iv)
                                    posting your content won't breach any contracts between you and a third party. You
                                    agree to settle any financial obligations resulting from posting your user
                                    content.</p>
                            </li>
                            <li>
                                <p>You should refrain from posting any content on or through the Service or sending to
                                    PostUp any content you deem confidential or proprietary. Any user-generated content
                                    you post or transmit to PostUp will be regarded as non-confidential and
                                    non-proprietary, and will be treated as such by PostUp. It may be used by PostUp in
                                    accordance with this EULA without notifying you and without any liability to
                                    PostUp.</p>
                            </li>
                            <li>
                                <p>Posting content entails giving up the rights to review or approve any marketing or
                                    promotional materials linked to that content beforehand. You also surrender any
                                    rights of privacy, publicity, or similar rights regarding your content or any part
                                    thereof. If any moral rights cannot be transferred or assigned, you hereby renounce
                                    them and commit to never asserting or supporting any action based on such moral
                                    rights concerning any content you post.</p>
                            </li>
                            <li>
                                <p>You are prohibited from posting any Content that could be considered abusive,
                                    bullying, defamatory, harassing, harmful, hateful, inaccurate, infringing, libelous,
                                    objectionable, obscene, offensive, pornographic, shocking, threatening, unlawful,
                                    violent, vulgar, or in violation of any applicable laws. Additionally, you must not
                                    promote any product, service, bigotry, discrimination, hatred, intolerance, racism,
                                    or incite violence through your content. Posting such objectionable content may
                                    result in third-party claims against you, and none of the rights granted to you in
                                    this agreement can be used as a defense against such claims.</p>
                            </li>
                            <li>
                                <p>The rights you grant in this EULA extend directly to the audience. This implies that
                                    the owners or operators of External Services or third-party sublicensees of PostUp
                                    won't bear individual liability to you or any other third party for user-generated
                                    content posted or used on such External Services or by such third-party sublicensees
                                    via the Service.</p>
                            </li>
                            <li>
                                <p>Your engagement with user-generated content and other users through the Service,
                                    including activities like liking, commenting, sharing, tagging, messaging, or
                                    ranking, as well as interactions via any External Service with sharing features
                                    provided by us, will be tracked and recorded by PostUp. By utilizing the Service,
                                    you consent to this monitoring of your activity.</p>
                            </li>
                            <li>
                                <p>You also agree not to use the Service for illegal or unlawful purposes. If you come
                                    across objectionable content on the platform, you should promptly report it to
                                    PostUp via email. While PostUp offers the option to report objectionable content as
                                    a courtesy, they are not obligated to take action upon receiving reports. However,
                                    PostUp reserves the right to take any necessary actions against users who post
                                    objectionable content, including issuing warnings, suspending or terminating
                                    accounts, removing content, or reporting users to law enforcement authorities. By
                                    using the Service, you acknowledge the possibility of being exposed to objectionable
                                    content.</p>
                            </li>
                            <li>
                                <p>To make it clear, PostUp won't be responsible for any use or misuse of user-generated
                                    content by any user or third-party sublicensee, whether resulting from a breach of
                                    this EULA or any other agreement between a user or third party and PostUp.</p>
                            </li>
                        </ul>
                        <h3>Links to Other Websites</h3>
                        <p>Our Service may contain links to third-party web sites or services that are not owned or
                            controlled by the Company.</p>
                        <p>The Company has no control over, and assumes no responsibility for, the content, privacy
                            policies, or practices of any third party web sites or services. You further acknowledge and
                            agree that the Company shall not be responsible or liable, directly or indirectly, for any
                            damage or loss caused or alleged to be caused by or in connection with the use of or
                            reliance on any such content, goods or services available on or through any such web sites
                            or services.</p>
                        <p>We strongly advise You to read the terms and conditions and privacy policies of any
                            third-party web sites or services that You visit.</p>
                        <h3>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h3>
                        <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all
                            faults and defects without warranty of any kind. To the maximum extent permitted under
                            applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and
                            their respective licensors and service providers, expressly disclaims all warranties,
                            whether express, implied, statutory or otherwise, with respect to the Service, including all
                            implied warranties of merchantability, fitness for a particular purpose, title and
                            non-infringement, and warranties that may arise out of course of dealing, course of
                            performance, usage or trade practice. Without limitation to the foregoing, the Company
                            provides no warranty or undertaking, and makes no representation of any kind that the
                            Service will meet Your requirements, achieve any intended results, be compatible or work
                            with any other software, applications, systems or services, operate without interruption,
                            meet any performance or reliability standards or be error free or that any errors or defects
                            can or will be corrected.</p>
                        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes
                            any representation or warranty of any kind, express or implied: (i) as to the operation or
                            availability of the Service, or the information, content, and materials or products included
                            thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the
                            accuracy, reliability, or currency of any information or content provided through the
                            Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on
                            behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs
                            or other harmful components.</p>
                        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations
                            on applicable statutory rights of a consumer, so some or all of the above exclusions and
                            limitations may not apply to You. But in such a case the exclusions and limitations set
                            forth in this section shall be applied to the greatest extent enforceable under applicable
                            law.</p>
                        <h3>Disputes Resolution</h3>
                        <p>If you have any concerns or disputes regarding the Service, you agree to attempt to
                            resolve the matter informally by contacting PostUp via email at support@postup.in</p>
                        <h3>Term and Termination</h3>
                        <p>This Agreement shall remain in effect until terminated by You or the Company.
                            The Company may, in its sole discretion, at any time and for any or no reason, suspend or
                            terminate this Agreement with or without prior notice.</p>
                        <p>This Agreement will terminate immediately, without prior notice from the Company, in the
                            event that you fail to comply with any provision of this Agreement. You may also terminate
                            this Agreement by deleting the Application and all copies thereof from your Device or from
                            your computer.</p>
                        <p>You can end this EULA by sending a written notification to PostUp via email at
                            suport@postup.in.</p>
                        <p>Upon termination of this Agreement, You shall cease all use of the Service and delete all
                            copies of the Application from your Device.</p>
                        <p>If you want to remove any of your Content from the Service, you can request PostUp by
                            contacting support@postup.in, we will delete your User Content within 30 days of receiving
                            your request.
                        </p>
                        <p>Termination of this Agreement will not limit any of the Company's rights or remedies at law
                            or in equity in case of breach by You (during the term of this Agreement) of any of your
                            obligations under the present Agreement.</p>
                        <h3>Indemnification</h3>
                        <p>You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates,
                            officers, employees, agents, partners and licensors (if any) harmless from any claim or
                            demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the
                            Service; (b) violation of this Agreement or any law or regulation; or (c) violation of
                            any right of a third party.</p>
                        <h3>Mobile</h3>
                        <p>Your mobile carrier’s normal messaging, data and other rates and fees will apply to your use
                            of the Mobile Services. In addition, downloading, installing or using certain Mobile
                            Services may be prohibited or restricted by your mobile carrier.</p>
                        <h3>No Warranties</h3>
                        <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with
                            all faults and defects without warranty of any kind. To the maximum extent permitted under
                            applicable law, the Company, on its own behalf and on behalf of its affiliates and its and
                            their respective licensors and service providers, expressly disclaims all warranties,
                            whether express, implied, statutory or otherwise, with respect to the Service, including
                            all implied warranties of merchantability, fitness for a particular purpose, title and
                            non-infringement, and warranties that may arise out of course of dealing, course of
                            performance, usage or trade practice. Without limitation to the foregoing, the Company
                            provides no warranty or undertaking, and makes no representation of any kind that the
                            Service will meet your requirements, achieve any intended results, be compatible or work
                            with any other software, applications, systems or services, operate without interruption,
                            meet any performance or reliability standards or be error free or that any errors or defects
                            can or will be corrected.</p>
                        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes
                            any representation or warranty of any kind, express or implied: (i) as to the operation or
                            availability of the Service, or the information, content, and materials or products
                            included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to
                            the accuracy, reliability, or currency of any information or content provided through the
                            Servcie; or (iv) that the Service, its servers, the content, or e-mails sent from or
                            on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware,
                            timebombs or other harmful components.</p>
                        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations
                            on applicable statutory rights of a consumer, so some or all of the above exclusions and
                            limitations may not apply to You. But in such a case the exclusions and limitations set
                            forth in this section shall be applied to the greatest extent enforceable under applicable
                            law. To the extent any warranty exists under law that cannot be disclaimed, the Company
                            shall be solely responsible for such warranty.</p>
                        <h3>Limitation of Liability</h3>
                        <p>Any disputes that arise between you and PostUp will be settled through binding individual
                            arbitration. By agreeing to this EULA, you and PostUp are each relinquishing the right to a
                            trial by jury or to participate in a class action.</p>
                        <p>To the maximum extent permitted by applicable law, in no event shall the Company or its
                            suppliers be liable for any special, incidental, indirect, or consequential damages
                            whatsoever (including, but not limited to, damages for loss of profits, loss of data or
                            other information, for business interruption, for personal injury, loss of privacy arising
                            out of or in any way related to the use of or inability to use the Service, third-party
                            software and/or third-party hardware used with the Service, or otherwise in connection
                            with any provision of this Agreement), even if the Company or any supplier has been advised
                            of the possibility of such damages and even if the remedy fails of its essential
                            purpose.</p>
                        <h3>Severability and Waiver</h3>
                        <h4>Severability</h4>
                        <p>If any provision of this Agreement or Terms are held to be unenforceable or invalid, such
                            provision will be changed and interpreted to accomplish the objectives of such provision to
                            the greatest extent possible under applicable law and the remaining provisions will continue
                            in full force and effect.</p>
                        <h4>Waiver</h4>
                        <p>Except as provided herein, the failure to exercise a right or to require performance of an
                            obligation under this Agreement shall not affect a party's ability to exercise such right or
                            require such performance at any time thereafter nor shall the waiver of a breach constitute
                            a waiver of any subsequent breach.</p>
                        <h3>Product Claims</h3>
                        <p>The Company does not make any warranties concerning the Service.</p>
                        <h3>Changes</h3>
                        <p>The Company reserves the right, at its sole discretion, to modify or replace this EULA and
                            Terms at any time. If a revision is material we will provide at least 30 days' notice prior
                            to any new terms taking effect. What constitutes a material change will be determined at
                            the sole discretion of the Company.</p>
                        <p>By continuing to access or use the Service after any revisions become effective, You
                            agree to be bound by the revised terms. If You do not agree to the new terms, You are no
                            longer authorized to use the Service.</p>
                        <h3>Governing Law</h3>
                        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement
                            and your use of the Service. The Service is intended for use in India. PostUp does not
                            claim that materials on the Service are suitable, legal, or accessible for use in any
                            locations besides India. Individuals who decide to access or use the Service from locations
                            outside India do so at their own discretion and are accountable for adhering to local laws,
                            if and where applicable. Accessing the Service from jurisdictions where its content or
                            practices are illegal, unauthorized, or penalized is strictly forbidden.</p>
                        <h3>Entire Agreement</h3>
                        <p>The Agreement constitutes the entire agreement between You and the Company regarding your use
                            of the Service and supersedes all prior and contemporaneous written or oral agreements
                            between You and the Company.</p>
                        <h3>Contact Us</h3>
                        <p>If you have any questions about this EULA or Terms, You can contact Us:</p>
                        <ul>
                            <li>By email: support@postup.in</li>
                        </ul>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <h2>PostUp Rules:</h2>
                        <p>Last updated: May 28, 2024</p>
                        <ul>
                            <li>
                                <p>No violence, offensive, gory and harmful content</p>
                            </li>
                            <li>
                                <p>No pornography</p>
                            </li>
                            <li>
                                <p>No deceptive content</p>
                            </li>
                            <li>
                                <p>No personal or identifiable information</p>
                            </li>
                            <li>
                                <p>No illegal activities</p>
                            </li>
                            <li>
                                <p>No copyright infringement</p>
                            </li>
                            <li>
                                <p>No spamming</p>
                            </li>
                            <li>
                                <p>Do not create multiple accounts to vote, report, evade ban or post same content to
                                    increase views</p>
                            </li>
                            <li>
                                <p>Your account could be banned permanently if you violate the rules.</p>
                            </li>
                            <li>
                                <p>If you find any content that violates the rules, report the content by clicking or
                                    tapping on [⋯] or [▾] in top of the post / comment</p>
                            </li>
                        </ul>
                        <h3>Contact Us</h3>
                        <p>If you have any questions about the rules, You can contact Us:</p>
                        <ul>
                            <li>By email: support@postup.in</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TermsofService
